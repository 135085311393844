<template>
  <v-container class="full-width-container">
    <Help v-if="loggedIn" />
    <Auth v-if="!loggedIn" />
  </v-container>
</template>

<script>
  import Help from '../components/Help.vue'
  import Auth from '../components/Auth.vue'

  export default {
    name: 'HelpView',
    components: {
      Help,
      Auth
    },
    computed: {
      loggedIn: function() {
        return this.$store.getters.loggedIn;
      },
    }
  }
</script>
